import React, { Suspense, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  TableContainer,
  Box,
  Popover,
  Chip,
  Theme,
  Tooltip,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import { DocumentNode, gql as GQL } from 'apollo-boost'
import { createResource } from 'src/network/fetcher'
import NoResults from 'src/components/errors/no-results'
import { gqlResource } from 'src/types/globalTypes'
import client from 'src/network/apollo-client'
import ErrorBoundary from 'src/components/errors/error-boundary'
import { Dialog, Loaders, DialogGroup, TableNew, DialogHeader, TitleWithToolTip, ModalGroup } from 'components'
import { theme } from 'src/styling'
import axios from 'src/network/axios'
import SnackBar from 'src/context/snackbar'
import { getJwtPayload } from 'src/auth/payload'
import moment from 'moment'
import { numberWithCommas } from '../../utils/formatting'
import { recordUserInteraction, userDownloadingDataFromModal, userSetItemStatus } from 'src/services/posthog'
import {
  applyFiltersToUrl,
  getAlertTypeAndIdFromUrl,
  getAttackSurfaceFiltersFromUrl,
  getPrivacyDataTypesFromUrl,
  getDateRangeFromUrl,
} from 'src/utils/filters'
import AutocompleteDataAttackSurface, { AttackSurfaceFiltersType } from 'src/modals/attack-surface/autocomplete-widget'
import { useLocation, useHistory } from 'react-router-dom'
import { checkVulnerabilitiesUploadStatus, isFileVulnerabilityProcessing } from 'src/utils/company'
import UpgradeTooltip from 'src/components/upgrade-subscription-tooltip/upgrade-tooltip'

// ========
// QUERIES
// ========
const QUERY_ORGANISATION_EXPORT_DATA_FILES: DocumentNode = GQL`
query organisation{
  organisation {
    exportDataFilesEnabled
    selfAttestationEnabled
  }
}
`

// ===================
// TYPES
// ===================
export type ContentDialogProps = {
  resource: gqlResource
  id?: number
  updateTotalCount?: (totalCount: number) => void
}

export type ModalProps = {
  onClose?: () => void
  filter?: {
    country?: string
  }
  id?: string
  createUserItem?: DocumentNode
  deleteUserItems?: DocumentNode
}

export interface SectionInterface {
  title?: string
  subtitle?: string
  headings?: string[]
  rowKeys?: string[]
  contentSentence: string
  showExportButton?: boolean
  alias?: string
  query?: DocumentNode
  getUserItems?: DocumentNode
  createUserItem?: DocumentNode
  deleteUserItems?: DocumentNode
  download: string
  enableFilter?: boolean
}
type DialogSectionProps = {
  sections: SectionInterface[]
  content?: (resource: ContentDialogProps) => React.ReactElement
  id?: string
}

type SectionProps = {
  section: SectionInterface
  handleIncreaseError: () => void
  content?: (resource: ContentDialogProps) => React.ReactElement
  id?: string
}

interface StyleProps {
  isAtLeastOneRowSelected: boolean
  isDigitalRiskProtectionModal: boolean
  isData: boolean
  viewingAsThirdParty: boolean
  isHeaderUnderlined: boolean
}

interface ContentObject {
  cve?: string
  ip?: string
  port?: number
  domain?: string
  server_ip?: string
  date?: string
  authority?: string
  score?: number
  from?: string
  subject?: string
  id_field?: string
  id?: number
  src?: string
  provider?: string
  uniqueHash?: string
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles<Theme, StyleProps>({
  contentSubTitle: {
    color: theme.colors.ui.gold,
    paddingTop: '0.7rem',
    fontSize: '1.2em',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '1rem',
    },
  },
  filterChip: {
    marginRight: '15px',
  },
  exportButton: {
    textTransform: 'none',
    width: '7rem',
    height: '2.25rem',
    fontSize: theme.fontSizes.mediumLarge,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.6rem',
    },
    backgroundColor: theme.colors.ui.whiteSmoke,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.colors.ui.snow,
    },
    color: theme.colors.ui.blueDark,
    padding: '8px 15px',
  },
  divided: ({ isHeaderUnderlined }) => ({
    borderBottom: isHeaderUnderlined ? '1px solid #E0E0E0' : '0px',
    paddingBottom: '1.2rem',
  }),
  mainDivided: {
    paddingBottom: '1.5rem',
  },
  tableData: {
    marginTop: '1.1rem',
    maxHeight: '800px',
    '&::-webkit-scrollbar': {
      width: '0.3em',
      height: '0.2rem',
    },
  },
  moreOptionsButton: { cursor: 'pointer' },
  moreOptionsPopover: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100px',
  },
  exportDataButton: {
    padding: '6px 0',
    cursor: 'pointer',
    borderBottom: `1px solid #E0E0E0`,
  },
  markAsContainer: ({ isData, isAtLeastOneRowSelected, viewingAsThirdParty }) => ({
    color: !isData || !isAtLeastOneRowSelected || viewingAsThirdParty ? theme.colors.ui.grey : theme.colors.ui.black,
  }),
  markAsButton: {
    padding: '6px 0',
    cursor: 'default',
  },
  markAsList: ({ isDigitalRiskProtectionModal }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginLeft: '10px',
    cursor: 'pointer',
    '& span:nth-child(2)': {
      display: isDigitalRiskProtectionModal ? 'none' : 'block',
    },
  }),
  thirdPartyCompanyWarning: {
    fontSize: theme.fontSizes.mediumLarge,
    opacity: '.7',
  },
})

// ===================
// FUNCTIONS
// ===================

/**
 * Helper function. Provided object: {a:{b:"Hello"}} and path: "a.b" returns "Hello"
 * @param obj Object to process
 * @param path Key to extract from object
 */
// const leaf = (obj: objType, path: string) => path.split('.').reduce((value, el) => (el in value ? value[el] : {}), obj)

/**
 * Creates dialog
 * @param param0 Props required to generate dialog. Table will be generated from resource + rowKeys (for rows).
 *               Ability to pass a function that returns
 */

const createContentStringArr = (items: any[], modalType: string, isActionedTable: boolean, note = null) => {
  return items?.map((item) => {
    let contentString: string
    let userItemId = item?.node?.userItemStatus?.id
    if (modalType === 'vulnerabilities') {
      contentString = `${item.cve};${item.ip}${note ? `|${note}` : ''}`
      userItemId = item?.id
    }
    if (modalType === 'certificates') contentString = `${item.node.ip};${item.node.hostsApplication.port}`
    if (modalType === 'open-ports' || modalType === 'databases' || modalType === 'possible-rdp')
      contentString = `${item.node.companyHost.ip};${item.node.port}`
    if (modalType === 'email-security') contentString = `${item.node.domain}`
    if (modalType === 'newly-registered-domains')
      contentString = `${item.node.domain};${item.node.score};${item.node.date}`

    if (modalType === 'breached-credentials') {
      contentString = `${item.node.breachMetadata.id};${item.node.breachMetadata.src}`
    }
    if (modalType === 'potential-squatting') {
      contentString = `${item.node.domain};${item.node.serverIp}`
    }
    if (modalType === 'company-privacy-data') {
      contentString = `${item.node.id};${item.node.domain};${item.node.uniqueHash}`
      userItemId = item?.node?.userItem?.id
    }
    return isActionedTable ? `${contentString};${userItemId}` : contentString
  })
}

const createUserItemIdArray = (checkBoxState: string[]) => {
  return checkBoxState.map((contentString) => {
    const stringArray = contentString.split(';')
    const stringArrayProcessed = stringArray[stringArray.length - 1].split('|')[0]
    return Number(stringArrayProcessed)
  })
}

const createContentObject = (stringWithNote: any, modalType: string, isActionedTable: boolean) => {
  const string = stringWithNote.split('|')[0]
  let contentObject: ContentObject = {}
  const stringArr = typeof string === 'string' && string.includes(';') && string.split(';')

  if (modalType === 'vulnerabilities') {
    contentObject = { cve: stringArr[0], ip: stringArr[1] }
    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }
  if (
    modalType === 'open-ports' ||
    modalType === 'databases' ||
    modalType === 'possible-rdp' ||
    modalType === 'certificates'
  ) {
    contentObject = { ip: stringArr[0], port: Number(stringArr[1]) }

    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }

  if (modalType === 'email-security') {
    contentObject.domain = isActionedTable ? stringArr[0] : string
    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }

  if (modalType === 'newly-registered-domains') {
    contentObject = { domain: stringArr[0], score: Number(stringArr[1]), date: stringArr[2] }
    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }

  if (modalType === 'breached-credentials') {
    contentObject = {
      id: Number(stringArr[0]),
      src: stringArr[1],
    }
    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }
  if (modalType === 'potential-squatting') {
    contentObject = {
      domain: stringArr[0],
      ip: stringArr[1],
    }
    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }
  if (modalType === 'company-privacy-data') {
    contentObject = {
      id: Number(stringArr[0]),
      domain: stringArr[1],
      uniqueHash: stringArr[2],
    }
    if (stringWithNote.split('|').length === 2) contentObject.note = stringWithNote.split('|')[1]
  }

  return contentObject
}

const mapItemType: { [key: string]: number } = {
  certificates: 1,
  'open-ports': 3,
  databases: 3,
  'possible-rdp': 3,
  vulnerabilities: 4,
  'email-security': 7,
  'breached-credentials': 8,
  'potential-squatting': 9,
  'newly-registered-domains': 10,
  'company-privacy-data': 11,
}

const mapItemStatus: { [key: string]: number } = {
  Acknowledged: 1,
  Mitigated: 2,
  'False positive': 3,
  Accepted: 7,
}

const userItemStatusBox = (
  classes,
  viewingAsThirdParty,
  organisation,
  handleClickMarkAsOption,
  isActionedTable,
  isAlertModal,
  id
) => (
  <Box className={classes.markAsContainer}>
    <Box className={classes.markAsButton}>Mark as</Box>
    <Box className={classes.markAsList} style={{ cursor: viewingAsThirdParty ? 'default' : 'pointer' }}>
      <span
        style={{
          cursor: viewingAsThirdParty || organisation?.selfAttestationEnabled === 0 ? 'default' : 'pointer',
          ...(id === 'breached-credentials' && { display: 'block' }),
        }}
        onClick={() => handleClickMarkAsOption('Mitigated')}
      >
        Mitigated
      </span>
      <span
        style={{
          cursor: viewingAsThirdParty || organisation?.selfAttestationEnabled === 0 ? 'default' : 'pointer',
        }}
        onClick={() => handleClickMarkAsOption('False positive')}
      >
        False positive
      </span>
      <span
        style={{
          cursor: viewingAsThirdParty || organisation?.selfAttestationEnabled === 0 ? 'default' : 'pointer',
        }}
        onClick={() => handleClickMarkAsOption('Acknowledged')}
      >
        Acknowledged
      </span>
      <span
        style={{
          cursor: viewingAsThirdParty || organisation?.selfAttestationEnabled === 0 ? 'default' : 'pointer',
        }}
        onClick={() => handleClickMarkAsOption('Accepted')}
      >
        Accepted
      </span>
      {(isActionedTable || isAlertModal) && (
        <span
          style={{
            cursor: viewingAsThirdParty || organisation?.selfAttestationEnabled === 0 ? 'default' : 'pointer',
          }}
          onClick={() => handleClickMarkAsOption('Remove status')}
        >
          Remove status
        </span>
      )}
    </Box>
  </Box>
)
const SectionData: React.FC<any> = ({
  section,
  handleIncreaseError,
  isActionedTable,
  handleSetIsActionedTable,
  isExploitedByRansomware,
  handleSetIsExploitedByRansomware,
  content,
  resource,
  resource2,
  id,
  handleViewMore,
  modalHasSearchFilter,
  attackSurfaceFilters,
  privacyDataFilters,
  duration,
  nonAggregateSearch,
  dataFilesResource,
}) => {
  const additionalHeading = ['Status', 'Note']
  if (id !== 'company-privacy-data' && id !== 'potential-squatting' && id !== 'newly-registered-domains')
    additionalHeading.splice(1, 0, 'Applied')
  const first = 30
  const [after, setAfter] = useState(0)

  let vulnersOrderRef = useRef('desc')
  let vulnersOrderByRef = useRef(0)

  const { viewingAsThirdParty } = getJwtPayload()
  const { thirdPartyCompanyName } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const history = useHistory()

  const resourceData = resource.read()
  const { organisation } = dataFilesResource.read()

  const [
    resourceData2totalCountWithStatuses,
    resourceData2totalCountWithoutStatuses,
    resourceData2totalCountRansomwareWithStatuses,
    resourceData2totalCountRansomwareWithoutStatuses,
  ] = resource2 ? resource2.map((res) => res.read()) : []

  const [data, setData] = useState(resourceData[section.alias]?.edges || [])
  const [hasNextPage, setHasNextPage] = useState(resourceData[section.alias]?.pageInfo?.hasNextPage)
  const [order, setOrder] = useState({ field: 'score', direction: 'DESC' })

  const alertParams = getAlertTypeAndIdFromUrl()
  const [isAlertModal, setIsAlertModal] = useState(section.isAlertModal)

  const [totalCount, setTotalCount] = useState(
    (section.isAlertModal
      ? resourceData?.cves?.totalCount
      : resourceData2totalCountWithoutStatuses
      ? resourceData2totalCountWithoutStatuses?.totalCountWithoutStatuses?.totalCount
      : resourceData?.totalCountWithoutStatuses?.totalCount) || 0
  )

  const [userItemTotalCount, setUserItemTotalCount] = useState(
    resourceData2totalCountWithStatuses
      ? resourceData2totalCountWithStatuses?.totalCountWithStatuses?.totalCount || 0
      : resourceData?.totalCountWithStatuses?.totalCount || 0
  )
  const [totalCountByRansomwareWithStatuses, setTotalCountByRansomwareWithStatuses] = useState(
    resourceData2totalCountRansomwareWithStatuses
      ? resourceData2totalCountRansomwareWithStatuses.totalCountByRansomwareWithStatuses?.totalCount || 0
      : resourceData?.totalCountByRansomwareWithStatuses?.totalCount || 0
  )
  const [totalCountByRansomwareWithoutStatuses, setTotalCountByRansomwareWithoutStatuses] = useState(
    resourceData2totalCountRansomwareWithoutStatuses
      ? resourceData2totalCountRansomwareWithoutStatuses?.totalCountByRansomwareWithoutStatuses?.totalCount || 0
      : resourceData?.totalCountByRansomwareWithoutStatuses?.totalCount || 0
  )

  const countExploitedByRansomware = isActionedTable
    ? totalCountByRansomwareWithStatuses
    : totalCountByRansomwareWithoutStatuses
  const [loading, setLoading] = useState(false)
  const [loadingNewItems, setLoadingNewItems] = useState(false)
  const { companyId, organisationId } = getJwtPayload()
  const [anchorElMoreOptions, setAnchorElMoreOptions] = useState(null)
  const [noStatusCheckBoxState, setNoStatusCheckBoxState] = useState<string[]>([])
  const [withStatusCheckBoxState, setWithStatusCheckBoxState] = useState<string[]>([])

  const checkBoxState = isActionedTable ? withStatusCheckBoxState : noStatusCheckBoxState
  const [vulnerabilityDownloading, setVulnerabilityDownloading] = useState(false)
  const isDigitalRiskProtectionModal =
    id === 'breached-credentials' ||
    id === 'potential-squatting' ||
    id === 'newly-registered-domains' ||
    id === 'company-privacy-data'

  //style
  const classes = useStyles({
    isAtLeastOneRowSelected: Boolean(checkBoxState.length),
    isDigitalRiskProtectionModal,
    isData: Boolean(data.length),
    viewingAsThirdParty: Boolean(viewingAsThirdParty),
    isHeaderUnderlined: !modalHasSearchFilter,
  })
  //snackbar
  const { enqueueSnackbar } = SnackBar.Consumer()

  const formatTextWithCountAndPlural = (title: string) => {
    if (!title) return ''

    let newTitle = title.replace('{totalCount}', numberWithCommas(totalCount) || '')

    switch (id) {
      case 'vulnerabilities':
        newTitle = newTitle.replace('{isPlural}', totalCount > 1 || totalCount === 0 ? 'ies' : 'y')
      case 'breached-credentials':
        newTitle = newTitle.replace('{isPlural}', totalCount > 1 || totalCount === 0 ? 'es' : '')
      default:
        newTitle = newTitle.replace('{isPlural}', totalCount > 1 || totalCount === 0 ? 's' : '')
    }

    return newTitle
  }

  const handleDownload = (download: string): Promise<void> => {
    recordUserInteraction(userDownloadingDataFromModal(download))

    const urlParts = [`/${download}/${companyId}/download/csv`]
    const urlParams = []
    if (modalHasSearchFilter) {
      if (Object.keys(attackSurfaceFilters).length) {
        const filtersParam = encodeURIComponent(JSON.stringify(attackSurfaceFilters))
        urlParams.push(`attackSurfaceFilters=${filtersParam}`)
      }

      if (Object.keys(privacyDataFilters).length) {
        const privacyFiltersParam = encodeURIComponent(JSON.stringify(privacyDataFilters))
        urlParams.push(`privacyDataFilters=${privacyFiltersParam}`)
      }

      if (nonAggregateSearch) urlParams.push('nonAggregateSearch=true')
      if (!!duration?.from && !!duration?.to) urlParams.push(`from=${duration.from}&to=${duration.to}`)
    }

    if (isActionedTable) {
      urlParams.push(`isActionedTable=${isActionedTable}`)
    }

    if (isExploitedByRansomware) {
      urlParams.push('exploitedByRansomware=true')
    }

    const queryString = urlParams.length > 0 ? `?${urlParams.join('&')}` : ''
    const url = urlParts + queryString
    if (download === 'vulnerabilities') {
      localStorage.setItem(
        'vulnerabilityDownloading',
        JSON.stringify([
          ...JSON.parse(localStorage.getItem('vulnerabilityDownloading')),
          {
            isProcessing: true,
            companyId,
            error: null,
            url: null,
            organisationId,
            fileType: 'csv',
          },
        ])
      )

      window.dispatchEvent(new Event('storage'))
      axios
        .get(url)
        .then(() => {
          checkVulnerabilitiesUploadStatus(enqueueSnackbar)
        })
        .catch((error) => {
          localStorage.setItem(
            'vulnerabilityDownloading',
            JSON.stringify(
              JSON.parse(localStorage.getItem('vulnerabilityDownloading')).filter(
                ({ companyIdState, organisationIdState, fileType }) => {
                  return companyId !== companyIdState && organisationId !== organisationIdState && fileType !== 'csv'
                }
              )
            )
          )

          enqueueSnackbar(`Sorry, we couldn't start your download. Please try again later.`, {
            variant: 'error',
          })
        })
    } else {
      setLoading(true)
      axios
        .get(url)
        .then((response) => {
          const date = moment().format('YYYY-MM-DD')
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', `${download}_${date}.csv`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          setLoading(false)
        })
        .catch(() => {
          //Display error message
          enqueueSnackbar(`Sorry, we couldn't start your download. Please try again later.`, {
            variant: 'error',
          })
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setHasNextPage(resourceData[section.alias]?.pageInfo?.hasNextPage)
    const totalCountWithStatusesProcessed = resourceData2totalCountWithStatuses
      ? resourceData2totalCountWithStatuses?.totalCountWithStatuses?.totalCount
      : resourceData?.totalCountWithStatuses?.totalCount
    const totalCountWithoutStatusesProcessed = resourceData2totalCountWithoutStatuses
      ? resourceData2totalCountWithoutStatuses?.totalCountWithoutStatuses?.totalCount
      : resourceData?.totalCountWithoutStatuses?.totalCount
    if (
      id !== 'company-privacy-data' &&
      id !== 'certificates' &&
      totalCountWithoutStatusesProcessed === 0 &&
      totalCountWithStatusesProcessed === 0
    )
      handleIncreaseError(section.alias)
  }, [resourceData])

  useEffect(() => {
    isAlertModal && setTotalCount(resourceData.cves.totalCount)
  }, [isAlertModal])

  useEffect(() => {
    setIsAlertModal(alertParams.filterBy !== null && alertParams.userAlertSentId !== null)
  }, [alertParams])

  const tempTotalCountWithoutStatuses = resourceData2totalCountRansomwareWithoutStatuses
    ? resourceData2totalCountRansomwareWithoutStatuses?.totalCountWithoutStatuses?.totalCount
    : resourceData?.totalCountWithoutStatuses?.totalCount
  const tempTotalCountWithStatuses = resourceData2totalCountWithStatuses
    ? resourceData2totalCountWithStatuses?.totalCountWithStatuses?.totalCount
    : resourceData?.totalCountWithStatuses?.totalCount
  if (
    id !== 'company-privacy-data' &&
    id !== 'certificates' &&
    tempTotalCountWithoutStatuses === 0 &&
    tempTotalCountWithStatuses === 0
  )
    return null

  const handleClickExploitedByRansomware = () => {
    handleSetIsExploitedByRansomware(!isExploitedByRansomware)
    const urlParams = applyFiltersToUrl({
      ips: attackSurfaceFilters?.ips,
      cves: attackSurfaceFilters?.cves,
      domains: attackSurfaceFilters?.domains,
      isActionedTable,
      exploitedByRansomware: !isExploitedByRansomware,
    })
    history.push(`${location.pathname}${!!urlParams?.length ? `?${urlParams}` : ''}`)
  }

  const handleClickActioned = () => {
    // reset ordering before view change
    vulnersOrderRef.current = 'desc'
    vulnersOrderByRef.current = 0
    handleSetIsActionedTable(!isActionedTable)
    const urlParams = applyFiltersToUrl({
      ips: attackSurfaceFilters?.ips,
      cves: attackSurfaceFilters?.cves,
      domains: attackSurfaceFilters?.domains,
      ports: attackSurfaceFilters?.ports,
      isActionedTable: !isActionedTable,
      exploitedByRansomware: isExploitedByRansomware && Boolean(totalCountByRansomwareWithStatuses),
      privacyTypes: privacyDataFilters,
      nonAggregateSearch,
    })
    history.push(`${location.pathname}${!!urlParams?.length ? `?${urlParams}` : ''}`)
  }

  const handleFetchMore = () => {
    client
      .query({
        query: section.query,
        variables: {
          first,
          after: after + 30,
          orderBy: order,
          withStatus: isActionedTable ? true : false,
          userItemType: mapItemType[id],
          ...(id === 'email-security' && { emailIssues: ['dmarc', 'spf'] }),
          ...(modalHasSearchFilter && attackSurfaceFilters && Object.keys(attackSurfaceFilters).length > 0
            ? attackSurfaceFilters
            : {}),
          ...(id === 'vulnerabilities' && { filterByRansomware: isExploitedByRansomware }),
          ...(id === 'company-privacy-data' && {
            types:
              privacyDataFilters?.length > 0
                ? privacyDataFilters
                : ['ads', 'canvas', 'cookies', 'keyloggers', 'sessRec', 'trackingPixel'],
          }),
        },
      })
      .then((res) => {
        setData(data.concat(res.data[section.alias]?.edges))
        setHasNextPage(res.data[section.alias]?.pageInfo?.hasNextPage)
        setAfter(after + 30)
      })
  }

  const handleRefreshData = async () => {
    const commonfilters = {
      withStatus: isActionedTable,
      userItemType: mapItemType[id],
      filterByRansomware: isExploitedByRansomware,
      ...(attackSurfaceFilters && Object.keys(attackSurfaceFilters).length > 0 ? attackSurfaceFilters : {}),
    }
    setAfter(0)
    setLoadingNewItems(true)
    await client.cache.reset()

    client
      .query({
        query: section.query,
        variables: {
          first,
          after: 0,
          withStatus: isActionedTable,
          orderBy: order,
          userItemType: mapItemType[id],
          ...(id === 'email-security' && { emailIssues: ['dmarc', 'spf'] }),
          ...(modalHasSearchFilter && attackSurfaceFilters && Object.keys(attackSurfaceFilters).length > 0
            ? attackSurfaceFilters
            : {}),
          ...(id === 'vulnerabilities' && { filterByRansomware: isExploitedByRansomware }),
          ...(id === 'company-privacy-data' && {
            types:
              privacyDataFilters?.length > 0
                ? privacyDataFilters
                : ['ads', 'canvas', 'cookies', 'keyloggers', 'sessRec', 'trackingPixel'],
          }),
        },
      })
      .then((res) => {
        // switch back to normal view if user removes all statuses
        if (isActionedTable && res.data[section.alias]?.edges.length === 0) {
          handleClickActioned()
          return
        }
        // switch back to normal view if user marks all exploited by ransomware
        if (isExploitedByRansomware && res.data[section.alias]?.edges.length === 0) {
          handleClickExploitedByRansomware()
          return
        }

        section.handleRefetch && section.handleRefetch()

        setData(res.data[section.alias]?.edges)

        if (id === 'vulnerabilities') {
          // with status
          client.query({ query: section.query2[0], variables: commonfilters }).then(({ data }) => {
            setUserItemTotalCount(data?.totalCountWithStatuses?.totalCount)
          })
          // without status
          client.query({ query: section.query2[1], variables: commonfilters }).then(({ data }) => {
            setTotalCount(data?.totalCountWithoutStatuses?.totalCount)
          })
          // ransomware with status
          client.query({ query: section.query2[2], variables: commonfilters }).then(({ data }) => {
            setTotalCountByRansomwareWithStatuses(data?.totalCountByRansomwareWithStatuses?.totalCount)
          })
          // ransomware without status
          client.query({ query: section.query2[3], variables: commonfilters }).then(({ data }) => {
            setTotalCountByRansomwareWithoutStatuses(data?.totalCountByRansomwareWithoutStatuses?.totalCount)
          })
        } else {
          setTotalCountByRansomwareWithStatuses(res.data?.totalCountByRansomwareWithStatuses?.totalCount)
          setTotalCountByRansomwareWithoutStatuses(res.data?.totalCountByRansomwareWithoutStatuses?.totalCount)
          setTotalCount(isAlertModal ? resourceData?.cves?.totalCount : res.data?.totalCountWithoutStatuses?.totalCount)
          setUserItemTotalCount(res.data?.totalCountWithStatuses?.totalCount)
        }

        setHasNextPage(res.data[section.alias]?.pageInfo?.hasNextPage)
        setAfter(0)
        setLoadingNewItems(false)
      })
      .catch((error) => {
        enqueueSnackbar(`Sorry, we couldn't load the data. Please refresh the page.`, {
          variant: 'error',
        })
        setLoadingNewItems(false)
        console.log(':: err', error)
      })
  }

  const handleClickMoreOptions = (event) => {
    setAnchorElMoreOptions(event.currentTarget)
  }

  const handleCloseMoreOptions = () => {
    setAnchorElMoreOptions(null)
  }

  const handleChangeCheckboxNote = (items, note, type = null, instance = null, actioned = false) => {
    const itemIdentifiers = createContentStringArr(items, id, isActionedTable, note)
    const tempState = actioned ? withStatusCheckBoxState : noStatusCheckBoxState

    const noStatusCheckBoxStateProccessed = tempState.map((state) => {
      if (type === 'vulnerabilities') {
        if (instance) {
          if (actioned) {
            if (
              state.split('|')[0] ===
              `${instance.cve};${instance.ip};${
                state.split('|')[0].split(';')[state.split('|')[0].split(';').length - 1]
              }`
            )
              return `${state.split('|')[0]}|${note}`
          } else if (state.split('|')[0] === `${instance.cve};${instance.ip}`) return `${state.split('|')[0]}|${note}`
          return state
        } else if (state.split(';')[0] === itemIdentifiers[0].split(';')[0]) return `${state.split('|')[0]}|${note}`
        return state
      } else {
        if (state.split('|')[0] === itemIdentifiers[0].split('|')[0]) return `${state.split('|')[0]}|${note}`
        return state
      }
    })

    actioned
      ? setWithStatusCheckBoxState(noStatusCheckBoxStateProccessed)
      : setNoStatusCheckBoxState(noStatusCheckBoxStateProccessed)
  }

  const handleChangeCheckbox = (items: any[], note = null, instanceObjectsArray = null) => {
    const checkBoxStateCurrentItems = checkBoxState.filter((state) => {
      return state.split(';')[0] === `${items[0].cve}`
    })
    let checkBoxStateTemp = checkBoxState

    if (instanceObjectsArray?.length === checkBoxStateCurrentItems.length) {
      checkBoxStateTemp = checkBoxState.map((state) => {
        if (state.split(';')[0] === `${items[0].cve}`) return state.split('|')[0]
        return state
      })
    }
    const itemIdentifiers = createContentStringArr(items, id, isActionedTable, note)

    const checkBoxStateWithoutNote = checkBoxStateTemp.map((state) => state.split('|')[0])

    const itemsAreChecked = itemIdentifiers.every((contentString) => checkBoxStateWithoutNote.includes(contentString))

    if (itemsAreChecked && note !== '') {
      const newCheckBoxState = checkBoxStateTemp.filter(
        (checkBoxItem) => !itemIdentifiers.includes(checkBoxItem.split('|')[0])
      )
      isActionedTable ? setWithStatusCheckBoxState(newCheckBoxState) : setNoStatusCheckBoxState(newCheckBoxState)
      return
    }

    isActionedTable
      ? setWithStatusCheckBoxState((prevState) => [...new Set([...prevState, ...itemIdentifiers])])
      : setNoStatusCheckBoxState((prevState) => [...new Set([...prevState, ...itemIdentifiers])])
  }

  const isCheckBoxChecked = (items: any[], some = false) => {
    if (!items?.length) return false
    const itemIdentifiers = createContentStringArr(items, id, isActionedTable)

    const checkBoxStateWithoutNote = checkBoxState.map((state) => state.split('|')[0])

    return some
      ? itemIdentifiers.some((contentString) => checkBoxStateWithoutNote.includes(contentString))
      : itemIdentifiers.every((contentString) => checkBoxStateWithoutNote.includes(contentString))
  }

  const handleChangeVulnerabilitiesCheckboxIcon = (instanceArray) => {
    if (!instanceArray?.length) return <CheckBoxOutlineBlankIcon />

    const itemIdentifiers = createContentStringArr(instanceArray, id, isActionedTable)
    if (!isCheckBoxChecked(instanceArray) && itemIdentifiers.some((instance) => checkBoxState.includes(instance)))
      return <IndeterminateCheckBoxOutlinedIcon />

    return <CheckBoxOutlineBlankIcon />
  }

  const handleClickMarkAsOption = (status: string) => {
    if (!Boolean(checkBoxState.length) || viewingAsThirdParty) return

    setLoadingNewItems(true)

    recordUserInteraction(userSetItemStatus(status, checkBoxState.length))

    const isRemoveStatus = status === 'Remove status'

    const idsToRemoveStatus =
      isAlertModal &&
      isRemoveStatus &&
      data.reduce((acc, node) => {
        const instanceObjectsArray = node.node.instances
          .map((instance) => ({
            cve: node.node.cve,
            ip: instance.ip,
            id: instance.userItemStatus?.id,
          }))
          .filter((instanceObject) => instanceObject.id)

        instanceObjectsArray.forEach((obj) => {
          const contentString = createContentStringArr([obj], 'vulnerabilities', isActionedTable)[0]

          if (checkBoxState.includes(contentString)) acc.push(obj.id)
        })
        return acc
      }, [])

    client
      .mutate({
        mutation: isRemoveStatus ? section.deleteUserItems : section.createUserItem,
        variables: {
          itemType: mapItemType[id],
          ...(isRemoveStatus && { ids: isAlertModal ? idsToRemoveStatus : createUserItemIdArray(checkBoxState) }),
          ...(!isRemoveStatus && {
            contents: checkBoxState.map((contentString) => createContentObject(contentString, id, isActionedTable)),
            status: mapItemStatus[status],
          }),
        },
      })
      .then(() => {
        if (!window.location.pathname.includes('/external-asm/digital-risk-protection')) {
          dispatch({
            type: 'SET_ATTACK_SURFACE_TABLE_REFRESH',
            payload: {
              attackSurfaceTableRefresh: true,
              scoreGaugeNeedsRefresh: true,
            },
          })
        }
        if (window.location.pathname.includes('/external-asm/attack-surface/map')) {
          dispatch({
            type: 'SET_ATTACK_SURFACE_MAP_VIEW_TABLE_REFRESH',
            payload: {
              attackSurfaceMapViewTableRefresh: true,
            },
          })
        }

        if (window.location.pathname.includes('/external-asm/digital-risk-protection')) {
          dispatch({
            type: 'SET_DRP_TABLE_REFRESH',
            payload: {
              drpTableRefresh: true,
            },
          })
        }

        handleRefreshData()
        enqueueSnackbar(
          `${
            isRemoveStatus
              ? `${checkBoxState.length} item${checkBoxState.length > 1 ? 's' : ''} removed`
              : `${checkBoxState.length} item${checkBoxState.length > 1 ? 's' : ''} marked as ${status.toLowerCase()}`
          }`,
          {
            variant: 'success',
          }
        )
      })
      .catch((error) => {
        enqueueSnackbar(
          `Sorry, we couldn't ${isRemoveStatus ? 'remove the status' : 'send your request'}. Please try again later.`,
          {
            variant: 'error',
          }
        )
        setLoadingNewItems(false)
        console.log(':: err', error)
      })
    isActionedTable ? setWithStatusCheckBoxState([]) : setNoStatusCheckBoxState([])
    setAnchorElMoreOptions(null)
  }
  const handleRefetch = (heading, direction) => {
    setLoadingNewItems(true)
    let order = {}
    if (heading === 0) {
      order = { field: 'score', direction: direction.toUpperCase() }
    } else if (heading === 1) {
      order = { field: 'cve', direction: direction.toUpperCase() }
    } else if (heading === 2) {
      order = { field: 'instances', direction: direction.toUpperCase() }
    } else if (heading === 3) {
      order = { field: 'exploitationStatus', direction: direction.toUpperCase() }
    }
    setOrder(order)
    setAfter(0)
    client
      .query({
        query: section.query,
        variables: {
          first,
          after: 0,
          withStatus: isActionedTable ? true : false,
          orderBy: order,
          userItemType: mapItemType[id],
          ...(id === 'vulnerabilities' && { filterByRansomware: isExploitedByRansomware }),
          ...(id === 'company-privacy-data' && {
            types:
              privacyDataFilters?.length > 0
                ? privacyDataFilters
                : ['ads', 'canvas', 'cookies', 'keyloggers', 'sessRec', 'trackingPixel'],
          }),
        },
      })
      .then((res) => {
        setData(res.data[section.alias]?.edges)
        setHasNextPage(res.data[section.alias]?.pageInfo?.hasNextPage)
        setLoadingNewItems(false)
      })
      .catch(() => {
        setLoadingNewItems(false)
      })
  }

  useEffect(() => {
    window.addEventListener('storage', () => {
      setVulnerabilityDownloading(
        isFileVulnerabilityProcessing(companyId, organisationId, 'csv', 'vulnerabilityDownloading')
      )
    })
  }, [])

  return (
    <Box className={classes.mainDivided} id={id}>
      <Box className={classes.divided}>
        <DialogHeader
          title={
            <TitleWithToolTip
              title={formatTextWithCountAndPlural(section.title)}
              contentSentence={formatTextWithCountAndPlural(section.contentSentence)}
              contentDrawer={section.contentDrawer}
            />
          }
          chipsData={section.chipsData}
          flipTitles={true}
          subtitle={(): React.ReactElement => (
            <>
              <Box style={section.subtitle || thirdPartyCompanyName ? { display: 'block' } : { display: 'none' }}>
                <Box className={classes.contentSubTitle}>
                  {section.subtitle ? section.subtitle.replace('{totalCount}', totalCount.toString() || '') : null}
                </Box>
                {viewingAsThirdParty && (
                  <Box className={classes.thirdPartyCompanyWarning}>
                    Viewing third party company: {thirdPartyCompanyName}
                  </Box>
                )}
              </Box>
            </>
          )}
        >
          {!isAlertModal && section.alias === 'cves' && (
            <Chip
              disabled={!countExploitedByRansomware}
              className={classes.filterChip}
              variant={isExploitedByRansomware ? 'default' : 'outlined'}
              onClick={handleClickExploitedByRansomware}
              label={`Exploited by ransomware: ${countExploitedByRansomware}`}
              color="primary"
            />
          )}
          {!isAlertModal && (modalHasSearchFilter || userItemTotalCount > 0) && (
            <Chip
              className={classes.filterChip}
              variant={isActionedTable ? 'default' : 'outlined'}
              disabled={loadingNewItems}
              onClick={handleClickActioned}
              label={`Actioned: ${userItemTotalCount}`}
              color="primary"
            />
          )}
          <Box className={classes.moreOptionsButton} onClick={handleClickMoreOptions}>
            <MoreVertIcon />
          </Box>
          <Popover
            open={Boolean(anchorElMoreOptions)}
            anchorEl={anchorElMoreOptions}
            onClose={handleCloseMoreOptions}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box className={classes.moreOptionsPopover}>
              {section.download && (
                <Box
                  className={classes.exportDataButton}
                  data-for="export-data-tooltip"
                  data-tip
                  style={{
                    color: organisation?.exportDataFilesEnabled === 0 ? theme.colors.ui.grey : theme.colors.ui.black,
                    cursor: organisation?.exportDataFilesEnabled !== 0 ? 'pointer' : 'default',
                  }}
                  onClick={() =>
                    !loading && organisation?.exportDataFilesEnabled !== 0 && handleDownload(section.download)
                  }
                >
                  {(section.download === 'vulnerabilities' && vulnerabilityDownloading) || loading ? (
                    <Loaders.Spinner size={18} />
                  ) : (
                    'Export data'
                  )}
                </Box>
              )}
              {organisation?.exportDataFilesEnabled === 0 && (
                <UpgradeTooltip id="export-data-tooltip" tooltipText="export data file" />
              )}
              {organisation?.exportDataFilesEnabled === 0 ? (
                <UpgradeTooltip id={'self-attestation'} tooltipText="self attestation">
                  <span data-for={'self-attestation'} data-tip>
                    {userItemStatusBox(
                      classes,
                      viewingAsThirdParty,
                      organisation,
                      handleClickMarkAsOption,
                      isActionedTable,
                      isAlertModal,
                      id
                    )}
                  </span>
                </UpgradeTooltip>
              ) : (
                <Tooltip
                  title={viewingAsThirdParty ? 'Option not available - currently viewing third party company' : ''}
                  placement="left"
                >
                  {userItemStatusBox(
                    classes,
                    viewingAsThirdParty,
                    organisation,
                    handleClickMarkAsOption,
                    isActionedTable,
                    isAlertModal,
                    id
                  )}
                </Tooltip>
              )}
            </Box>
          </Popover>
        </DialogHeader>
      </Box>
      {/* Handle no results found  */}
      <>
        {section.enableFilter && modalHasSearchFilter && (
          <AutocompleteDataAttackSurface
            withStatus={isActionedTable}
            userItemType={mapItemType[id]}
            exploitedByRansomware={isExploitedByRansomware}
            filterQuery={section.filterQuery}
            filterPlaceholder={section.filterPlaceholder}
            privacyDataFilters={privacyDataFilters}
            showDatePicker={id === 'certificates'}
          />
        )}

        {loadingNewItems ? (
          <DialogGroup>
            <Loaders.Text lines={9} width={'100%'} />
          </DialogGroup>
        ) : (
          <TableContainer className={classes.tableData} id={id + '-table-container'}>
            {content ? (
              content({
                handleChangeCheckbox,
                handleChangeCheckboxNote,
                isCheckBoxChecked,
                isActionedTable,
                items: isAlertModal ? resourceData[section.alias]?.edges : data,
                handleChangeVulnerabilitiesCheckboxIcon,
                vulnersOrderRef,
                vulnersOrderByRef,
                hasNextPage,
                handleFetchMore,
                handleRefetch,
                isCheckBoxDisabled: viewingAsThirdParty || organisation?.selfAttestationEnabled === 0,
                isAlertModal,
                getCveInstancesQuery: section.queryCveInstances,
                attackSurfaceFilters,
                nonAggregateSearch,
              }) //We can also pass in the entire table
            ) : (
              <TableNew
                isActionedTable={isActionedTable}
                handleViewMore={handleViewMore}
                headings={isActionedTable ? [...section.headings, ...additionalHeading] : section.headings}
                items={data}
                hasMore={hasNextPage}
                fetchMore={handleFetchMore}
                rowKeys={
                  isActionedTable
                    ? [
                        ...section.rowKeys,
                        id === 'company-privacy-data'
                          ? 'node.userItem.userItemStatus.description'
                          : 'node.userItemStatus.description',
                        id !== 'company-privacy-data' &&
                          id !== 'potential-squatting' &&
                          id !== 'newly-registered-domains' &&
                          'node.applied',
                        'node.userItem.note',
                      ].filter(Boolean)
                    : section.rowKeys
                }
                handleChangeCheckbox={handleChangeCheckbox}
                handleChangeCheckboxNote={handleChangeCheckboxNote}
                isCheckBoxChecked={isCheckBoxChecked}
                isCheckBoxDisabled={viewingAsThirdParty || organisation?.selfAttestationEnabled === 0}
                tableName={id}
              />
            )}
          </TableContainer>
        )}
      </>
    </Box>
  )
}

const Section: React.FC<SectionProps> = ({
  section,
  handleIncreaseError,
  content,
  id,
  isActionedTable,
  handleSetIsActionedTable,
  handleViewMore,
}) => {
  const location = useLocation()
  const [attackSurfaceFilters, setAttackSurfaceFilters] = useState<AttackSurfaceFiltersType>(
    getAttackSurfaceFiltersFromUrl(location.search) as AttackSurfaceFiltersType
  )

  const [privacyDataFilters, setPrivacyDataFilters] = useState<string[]>(getPrivacyDataTypesFromUrl())
  const [duration, setDuration] = useState(getDateRangeFromUrl())
  const [isExploitedByRansomware, setIsExploitedByRansomware] = useState(false)
  const [nonAggregateSearch, setNonAggregateSearch] = useState(false)
  const handleSetIsExploitedByRansomware = (exploitedByRansomware: boolean) =>
    setIsExploitedByRansomware(exploitedByRansomware)
  const modalHasSearchFilter = id !== 'email-security' || id === 'company-privacy-data'
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search)
    if (modalHasSearchFilter) {
      setAttackSurfaceFilters(getAttackSurfaceFiltersFromUrl(location.search) as AttackSurfaceFiltersType)
      const isExploitedByRansomware = urlSearchParams.get('ransomware')
      setIsExploitedByRansomware(Boolean(isExploitedByRansomware))
      setPrivacyDataFilters(getPrivacyDataTypesFromUrl())
    }
    const isActionedTable = urlSearchParams.get('actionedtable')
    handleSetIsActionedTable(Boolean(isActionedTable))
    const nonAggregateSearch = urlSearchParams.get('nonAggregateSearch')
    setNonAggregateSearch(Boolean(nonAggregateSearch))
    id === 'certificates' && setDuration(getDateRangeFromUrl())
  }, [location.search])
  const dataFilesResource = createResource(QUERY_ORGANISATION_EXPORT_DATA_FILES)

  const resource = createResource(section.query, {
    variables: {
      first: 30,
      after: 0,
      orderBy: { field: 'score', direction: 'DESC' },
      withStatus: isActionedTable ? true : false,
      userItemType: mapItemType[id],
      ...(id === 'email-security' && { emailIssues: ['dmarc', 'spf'] }),
      ...(modalHasSearchFilter && attackSurfaceFilters && Object.keys(attackSurfaceFilters).length > 0
        ? attackSurfaceFilters
        : {}),
      ...(id === 'vulnerabilities' && { filterByRansomware: isExploitedByRansomware }),
      ...(id === 'company-privacy-data' && {
        types:
          privacyDataFilters?.length > 0
            ? privacyDataFilters
            : ['ads', 'canvas', 'cookies', 'keyloggers', 'sessRec', 'trackingPixel'],
      }),
      ...(id === 'certificates' && !!duration?.from && !!duration?.to && { duration }),
      ...(id !== 'email-security' && { nonAggregateSearch }),
    },
  })
  const resource2 =
    section?.query2 &&
    section?.query2.map((sec) =>
      createResource(sec, {
        variables: {
          withStatus: isActionedTable ? true : false,
          userItemType: mapItemType[id],
          ...(id === 'email-security' && { emailIssues: ['dmarc', 'spf'] }),
          ...(modalHasSearchFilter && attackSurfaceFilters && Object.keys(attackSurfaceFilters).length > 0
            ? attackSurfaceFilters
            : {}),
          ...(id === 'vulnerabilities' && { filterByRansomware: isExploitedByRansomware }),
          ...(id !== 'email-security' && { nonAggregateSearch }),
        },
      })
    )

  return (
    <Box padding={'11px'}>
      <SectionData
        handleViewMore={handleViewMore}
        key={location.key}
        id={id}
        isActionedTable={isActionedTable}
        handleSetIsActionedTable={handleSetIsActionedTable}
        isExploitedByRansomware={isExploitedByRansomware}
        handleSetIsExploitedByRansomware={handleSetIsExploitedByRansomware}
        resource={resource}
        resource2={resource2}
        dataFilesResource={dataFilesResource}
        section={section}
        handleIncreaseError={handleIncreaseError}
        content={content}
        modalHasSearchFilter={modalHasSearchFilter}
        attackSurfaceFilters={attackSurfaceFilters}
        privacyDataFilters={privacyDataFilters}
        duration={duration}
        nonAggregateSearch={nonAggregateSearch}
      />
    </Box>
  )
}

//Loader
const FallbackDialog: React.FC = () => (
  <Box>
    <DialogGroup>
      <Loaders.Line />
    </DialogGroup>
    <DialogGroup>
      <Loaders.Text lines={9} width={'100%'} />
    </DialogGroup>
  </Box>
)

//Wrapper
const DialogSection: React.FC<DialogSectionProps> = (props) => {
  //media query
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [sectionError, setSectionError] = useState([])
  const propsId = props.id
  const [dataViewMore, setDataViewMore] = useState(null)
  const [dataViewMoreLoading, setDataViewMoreLoading] = useState(null)
  const [dataViewMoreId, setDataViewMoreId] = useState(null)
  const uiState = useSelector((state) => state.ui)

  const [isActionedTable, setIsActionedTable] = useState(uiState.isAcknowledgeTable)
  const handleSetIsActionedTable = (isActionedTable: boolean) => setIsActionedTable(isActionedTable)

  const companyPrivacyDataStyles: {
    maxWidth: string
    minHeight: string
    marginLeft: string
  } = {
    maxWidth: '1100px',
    minHeight: '100%',
    marginLeft: '144px',
  }

  const certificatesStyles: {
    maxWidth: 'lg'
    minHeight: string
    marginLeft: string
  } = {
    maxWidth: 'lg',
    minHeight: '100%',
    marginLeft: '144px',
  }
  const vulnerabilitiesStyles: {
    maxWidth: string
  } = {
    maxWidth: '1100px',
  }

  const newlyRegisteredDomainsStyles: {
    maxWidth: string
  } = {
    maxWidth: '1200px',
  }

  const getStyles = () => {
    switch (propsId) {
      case 'certificates':
        return certificatesStyles
      case 'vulnerabilities':
        return vulnerabilitiesStyles
      case 'newly-registered-domains':
        return newlyRegisteredDomainsStyles
      case 'company-privacy-data':
        return companyPrivacyDataStyles
      default:
        return {}
    }
  }

  const handleIncreaseError = (alias: string) => {
    setSectionError([...new Set(sectionError.concat(alias))])
  }

  if (sectionError.length === props.sections.length) {
    return (
      <Dialog onClose={props.onClose} fullScreen={fullScreen}>
        <Box height="40vh">
          <NoResults message={'No results found'} />
        </Box>
      </Dialog>
    )
  }

  const handleViewMore = (id, isActionedTable) => {
    setIsActionedTable(isActionedTable)
    setDataViewMoreLoading(true)
    setDataViewMoreId(id)
    client
      .query({ query: GET_EMAIL_DETAILS, variables: { id } })
      .then((res) => {
        setDataViewMoreLoading(false)
        setDataViewMore(res.data)
      })
      .catch((e) => {
        setDataViewMoreLoading(false)
      })
  }

  if (dataViewMoreLoading)
    return (
      <Dialog onClose={() => setDataViewMore(null)} fullScreen={fullScreen}>
        <Box>
          <ModalGroup>
            <Loaders.Line />
          </ModalGroup>
          <ModalGroup>
            <Loaders.Text lines={9} width={'100%'} />
          </ModalGroup>
        </Box>
      </Dialog>
    )

  if (!dataViewMore)
    return (
      <Dialog onClose={props.onClose} fullScreen={fullScreen} {...getStyles()}>
        <ErrorBoundary>
          <Suspense fallback={<FallbackDialog />}>
            {props.sections.map((section, index) => {
              return (
                <Section
                  isActionedTable={isActionedTable}
                  handleSetIsActionedTable={handleSetIsActionedTable}
                  handleViewMore={handleViewMore}
                  id={props.id}
                  handleIncreaseError={handleIncreaseError}
                  content={props.content}
                  key={index}
                  section={section}
                />
              )
            })}
          </Suspense>
        </ErrorBoundary>
      </Dialog>
    )
}

export default DialogSection
