import Cookies from 'js-cookie'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { getFirstActiveTab } from './router-helpers'
import { useSelector } from 'react-redux'

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<any>
}

const PublicRoute: React.FC<PublicRouteProps> = ({ component: Component, path, ...rest }) => {
  const { isAccountDeactivated } = useSelector((state) => state.ui)
  const token = Cookies.get('auth-token')
  const { pocExpired, freeTrialExpired } = useSelector((state) => state.auth)

  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) {
          if (!pocExpired) return <Redirect to={getFirstActiveTab()} />
          if (pocExpired && path !== '/poc-expired') return <Redirect to="/poc-expired" />
          if (freeTrialExpired && path !== '/trial-expired') return <Redirect to="/trial-expired" />
        }

        if (!token) {
          if (isAccountDeactivated && path !== '/account-deactivated') return <Redirect to="/account-deactivated" />
          if (!isAccountDeactivated) {
            if (path === '/redeem' || path === '/logged-out' || path === '/login-error') return <Component {...props} />
            return <Redirect to="/login" />
          }
        }

        return <Component {...props} />
      }}
    />
  )
}

export default PublicRoute
