import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, makeStyles, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { theme } from 'src/styling'
import { GlobalSpinnerLoader } from '../loaders/spinner/spinner'
import { useFeatureFlagPayload } from 'posthog-js/react'
import { useSelector } from 'react-redux'
import { HelpOutline } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  dialogPaper: {
    [theme.breakpoints.up('md')]: {
      margin: 0,
      overflow: 'hidden',
    },
  },
  dialogContent: {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    height: 'fit-content',
  },
  titleBar: {
    padding: 0,
    margin: 0,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.ui.blue,
    padding: 0,
  },
  titleText: {
    flexGrow: 1,
    textAlign: 'center',
    color: theme.colors.ui.white,
    fontWeight: 400,
  },
  subTitle: {
    color: theme.colors.ui.grey,
    fontWeight: 300,
    marginTop: 10,
    padding: '5px 10px',
    fontSize: '1rem',
    textAlign: 'center',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 2,
  },
  calendlyContainer: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      margin: -66,
      marginBottom: 0,
      width: '100%',
      minWidth: '1000px',
    },
  },
  hideCalendlyLoader: {
    '& iframe': {
      zIndex: 1,
    },
  },
}))

const CalendlyDialog = () => {
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(true)
  const { email, firstName } = useSelector((state) => state.auth)
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }
  const posthogCalendlyUrl = useFeatureFlagPayload(process.env.REACT_APP_PUBLIC_POSTHOG_URL)

  useCalendlyEventListener({
    onEventTypeViewed: (e) => {
      // Event when the event type is viewed (widget has loaded)
      setLoading(false)
    },
  })
  if (!posthogCalendlyUrl) return null

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      maxWidth="lg"
      style={{ margin: 0, padding: 0 }}
      scroll="body"
    >
      <DialogTitle disableTypography className={classes.titleBar}>
        <div className={classes.titleContainer}>
          <HelpOutline style={{ color: theme.colors.ui.gold, margin: '12px' }} />
          <Typography variant="h6" className={classes.titleText}>
            Discover the Orpheus Portal
          </Typography>
          <IconButton onClick={handleClose} style={{ cursor: 'pointer' }}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      </DialogTitle>
      <Typography variant="h6" className={classes.subTitle}>
        Let us show you how we can help you reduce your cyber risk
      </Typography>
      <DialogContent className={classes.dialogContent}>
        {loading && (
          <div className={classes.loaderContainer}>
            <GlobalSpinnerLoader />
          </div>
        )}
        <div className={`${classes.calendlyContainer} ${classes.hideCalendlyLoader}`}>
          {posthogCalendlyUrl && (
            <InlineWidget
              url={posthogCalendlyUrl as string}
              styles={{ height: '700px', minWidth: '320px' }}
              prefill={{ email, name: firstName }}
              data-resize="true"
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CalendlyDialog
