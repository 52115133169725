import Error from 'src/components/errors/error'
import React from 'react'
import { Widget } from 'components'
import { isHttpAuthError } from 'src/utils/network'
import Cookies from 'js-cookie'
import { redirectToLoggedOutPage } from 'src/utils/redirect-to-logged-out-page'

// ===================
// TYPES
// ===================
type Props = {
  error?: {
    networkError?: {
      statusCode: number
    }
  }
  isWidget: boolean
  loading: boolean
  handleRetry: (e: React.FormEvent<HTMLInputElement>) => void
}

// ===================
// COMPONENT
// ===================

const ErrorFallback: React.FC<Props> = (props) => {
  const error = props.error

  const errorCode =
    error?.graphQLErrors && error.graphQLErrors.length > 0
      ? error.graphQLErrors[0]?.message === 'FORBIDDEN'
        ? 'FORBIDDEN'
        : error.graphQLErrors[0]?.extensions?.code
      : 'INTERNAL_SERVER_ERROR'

  if (
    (isHttpAuthError(error?.networkError?.statusCode) ||
      (error?.networkError && !Cookies.get('auth-token')) ||
      (!error?.graphQLErrors?.length &&
        error?.networkError &&
        !Object.keys(error?.networkError).length &&
        error?.message === 'Network error: Failed to fetch')) &&
    error?.networkError?.statusCode !== 504
  ) {
    redirectToLoggedOutPage()
  }

  if (props.isWidget) {
    return <Widget error={errorCode} />
  } else {
    return <Error errorCode={errorCode} />
  }
}

export default ErrorFallback
