import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { theme } from 'src/styling'
import { Dialog } from '@material-ui/core'
import SelfSignUpLandingPage from '../../pages/self-service-signup/landing-page'
import { EnumType } from 'typescript'

type Props = {
  children?: React.ReactElement
  tooltipText?: string
  id: string
  align?: 'top' | 'right' | 'left' | 'bottom'
}

// ===================
// STYLES
// ===================
const useStyles = makeStyles({
  reactTooltip: {
    padding: '10px',
    lineHeight: '30px',
  },
  upgradeLink: {
    color: 'white',
    fontSize: `${theme.fontSizes.mediumLarge} !important`,
    fontWeight: 'bold',
    '&:hover': { color: 'white' },
    borderBottom: '1px solid white',
    cursor: 'pointer',
  },
  subscriptionDialog: {
    marginLeft: '144px',
    zIndex: '-1',
  },
})

const UpgradeTooltip: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  // subscription hooks
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false)

  const handleOpenUpgradeModal = async (state: boolean) => {
    setIsSubscriptionOpen(state)
  }
  return (
    <>
      <span data-tip data-for={props.id}>
        {props.children}
      </span>
      <ReactTooltip
        className={classes.reactTooltip}
        backgroundColor={theme.colors.ui.blueDark}
        id={props.id}
        place={props.align ? props.align : 'left'}
        effect="solid"
        clickable
        delayHide={100}
      >
        <span>
          Please{' '}
          <a className={classes.upgradeLink} target="_blank" onClick={() => handleOpenUpgradeModal(true)}>
            upgrade
          </a>{' '}
          {`your subscription to access ${props.tooltipText}`}
        </span>
      </ReactTooltip>
      {isSubscriptionOpen && (
        <Dialog
          className={classes.subscriptionDialog}
          open={isSubscriptionOpen}
          onClose={() => handleOpenUpgradeModal(false)}
          maxWidth={'lg'}
          aria-labelledby="subscription-info-dialog-title"
        >
          <SelfSignUpLandingPage step={'subscription-info'} />
        </Dialog>
      )}
    </>
  )
}
export default UpgradeTooltip
